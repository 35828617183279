import { Component } from '@angular/core';

@Component({
  selector: 'progress-track',
  standalone: true,
  imports: [],
  templateUrl: './progress-track.component.html',
  styleUrl: './progress-track.component.scss'
})
export class ProgressTrackComponent {

}
