<div class="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
    <div class="p-4 pb-0 mb-0 rounded-t-2xl">
      <h6 class="mb-0 dark:text-white">Revisiones</h6>
    </div>
    <div class="flex-auto p-4 pb-0">
      <ul class="flex flex-col pl-0 mb-0 rounded-lg">
        @for (row of progressData; track $index) {
          <li class="relative flex items-center px-0 py-2 mb-0 border-0 rounded-t-inherit text-inherit">
            <div class="w-full">
              <div class="flex mb-2">
                <span class="mr-2 text-sm font-semibold leading-normal capitalize">{{row.description}}</span>
                <span class="ml-auto text-sm font-semibold leading-normal">{{row.percentage}}%</span>
              </div>
              <div>
                <div class="flex h-2 overflow-visible text-xs bg-gray-200 rounded-lg">
                  <div class="flex flex-col justify-center h-2 -ml-px overflow-hidden text-center text-white bg-gradient-to-tl from-{{colorsArray[$index%colorsArray.length].color1}} to-{{colorsArray[$index%colorsArray.length].color2}} percentageWidth-{{row.percentage}} transition-width duration-600 ease rounded-1 whitespace-nowrap"></div>
                </div>
              </div>
            </div>
          </li>
        }
      </ul>
    </div>
</div>