import { Component } from '@angular/core';

@Component({
  selector: 'activity-members',
  standalone: true,
  imports: [],
  templateUrl: './activity-members.component.html',
  styleUrl: './activity-members.component.scss'
})
export class ActivityMembersComponent {

}
