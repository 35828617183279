<div class="relative flex flex-col min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
    <div class="flex-auto p-4">
    <div class="flex flex-row -mx-3">
        <div class="flex-none w-2/3 max-w-full px-3">
            <div>
                <p class="mb-0 font-sans text-sm font-semibold leading-normal uppercase dark:text-white dark:opacity-60">Today's Money</p>
                <h5 class="mb-2 font-bold dark:text-white">$53,000</h5>
                <p class="mb-0 dark:text-white dark:opacity-60">
                    <span class="text-sm font-bold leading-normal text-emerald-500">+55%</span>
                    Since yesterday
                </p>
            </div>
        </div>
        <div class="px-3 text-right basis-1/3">
            <div class="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                <i class="ni leading-none ni-money-coins text-lg relative top-3.5 text-white"></i>
            </div>
        </div>
    </div>
    </div>
</div>
