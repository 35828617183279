import { Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'stock-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './stock-alert.component.html',
  styleUrl: './stock-alert.component.scss'
})
export class StockAlertComponent {
  @Input() alertStock ?: any[]; 
  currentDate: Date = new Date(); 
}
