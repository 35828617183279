import { Component } from '@angular/core';

@Component({
  selector: 'graphic-info',
  standalone: true,
  imports: [],
  templateUrl: './graphic-info.component.html',
  styleUrl: './graphic-info.component.scss'
})
export class GraphicInfoComponent {

}
