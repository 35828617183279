<nav navbar-main class="relative flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start" navbar-scroll="true">
    <div class="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
      <nav>
        <!-- breadcrumb -->
        <ol class="flex flex-wrap pt-1 mr-12 bg-transparent rounded-lg sm:mr-16">
          <li class="text-sm leading-normal breadcrumb-item">
            <a class="text-white" href="javascript:;">
              <i class="leading-none ni ni-box-2"></i>
            </a>
          </li>
          <li class="text-sm pl-2 leading-normal before:float-left before:pr-2 before:text-white before:content-['/']">
            <a class="text-white opacity-50" href="javascript:;">Paginas</a>
          </li>
          <li class="text-sm pl-2 capitalize leading-normal text-white before:float-left before:pr-2 before:text-white before:content-['/']" aria-current="page">Inicio</li>
        </ol>
        <h6 class="mb-0 font-bold text-white capitalize">Inicio</h6>
      </nav>

      <div class="flex items-center">
        <a mini-sidenav-burger href="javascript:;" class="hidden p-0 text-sm text-white transition-all ease-nav-brand xl:block" aria-expanded="false">
          <div class="w-4.5 overflow-hidden">
            <i class="ease mb-0.75 relative block h-0.5 translate-x-[5px] rounded-sm bg-white transition-all"></i>
            <i class="ease mb-0.75 relative block h-0.5 rounded-sm bg-white transition-all"></i>
            <i class="ease relative block h-0.5 translate-x-[5px] rounded-sm bg-white transition-all"></i>
          </div>
        </a>
      </div>

      <div class="flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto" id="navbar">
        <div class="flex items-center md:ml-auto md:pr-4">
          <div class="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease">
            <span class="text-sm ease leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input type="text" class="pl-9 text-sm focus:shadow-primary-outline dark:bg-slate-850 dark:placeholder:text-white/80 dark:text-white/80 ease w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none focus:transition-shadow" placeholder="Buscar" />
          </div>
        </div>
        <ul class="flex flex-row justify-end pl-0 mb-0 list-none md-max:w-full">
          <!-- online builder btn  -->
          <!-- <li class="flex items-center">
          <a class="inline-block px-8 py-2 mb-0 mr-4 text-xs font-bold text-center uppercase align-middle transition-all ease-in bg-transparent border border-solid rounded-lg shadow-none cursor-pointer leading-pro border-fuchsia-500 hover:-translate-y-px active:shadow-xs text-fuchsia-500 hover:border-fuchsia-500 active:bg-fuchsia-500 active:hover:text-fuchsia-500 hover:text-fuchsia-500 tracking-tight-rem hover:bg-transparent hover:opacity-75 hover:shadow-none active:text-white active:hover:bg-transparent" target="_blank" href="https://www.creative-tim.com/builder/soft-ui?ref=navbar-dashboard&amp;_ga=2.76518741.1192788655.1647724933-1242940210.1644448053">Online Builder</a>
        </li> -->
          <li class="flex items-center">
            <a href="/login" class="block px-0 py-2 text-sm font-semibold text-white transition-all ease-nav-brand">
              <i class="fa fa-user sm:mr-1" aria-hidden="true"></i>
              <span class="hidden sm:inline">Iniciar sesión</span>
            </a>
          </li>
          <li class="flex items-center pl-4 xl:hidden">
            <a sidenav-trigger class="block p-0 text-sm text-white transition-all ease-nav-brand" href="javascript:;" aria-expanded="false">
              <div class="w-4.5 overflow-hidden">
                <i class="ease mb-0.75 relative block h-0.5 rounded-sm bg-white transition-all"></i>
                <i class="ease mb-0.75 relative block h-0.5 rounded-sm bg-white transition-all"></i>
                <i class="ease relative block h-0.5 rounded-sm bg-white transition-all"></i>
              </div>
            </a>
          </li>
          <!-- notifications -->

          <li class="relative flex items-center px-4">
            <p class="hidden transform-dropdown-show"></p>
            <a dropdown-trigger href="javascript:;" class="block p-0 text-sm text-white transition-all ease-nav-brand" aria-expanded="false">
              <i class="cursor-pointer fa fa-bell" aria-hidden="true"></i>
            </a>

            <ul dropdown-menu class="text-sm transform-dropdown before:font-awesome before:leading-default before:duration-350 before:ease lg:shadow-3xl duration-250 min-w-44 before:sm:right-7 before:text-5.5 dark:bg-slate-850 pointer-events-none absolute right-0 top-0 z-50 origin-top list-none rounded-lg border-0 border-solid border-transparent bg-white bg-clip-padding px-2 py-4 text-left text-slate-500 opacity-0 transition-all before:absolute before:right-2 before:left-auto before:top-0 before:z-50 before:inline-block before:font-normal before:text-white before:antialiased before:transition-all before:content-['\f0d8'] sm:-mr-6 lg:absolute lg:right-0 lg:left-auto lg:mt-2 lg:block lg:cursor-pointer">
              <!-- add show class on dropdown open js -->
              <notification class="relative mb-2"/>
              <notification class="relative mb-2"/>
              <notification class="relative"/>
            </ul>
          </li>
        </ul>
      </div>
    </div>
</nav>
