<a class="group ease py-1.2 clear-both block w-full whitespace-nowrap rounded-lg bg-transparent px-4 duration-300 hover:bg-gray-200 hover:text-slate-700 dark:hover:bg-gray-200/80 lg:transition-colors" href="javascript:;">
    <div class="flex py-1">
      <div class="my-auto">
        <img src="../assets/argon-tailwind/img/team-2.jpg" class="inline-flex items-center justify-center mr-4 text-sm text-white h-9 w-9 max-w-none rounded-xl" />
      </div>
      <div class="flex flex-col justify-center">
        <h6 class="mb-1 text-sm font-normal leading-normal group-hover:text-slate-700 dark:text-white"><span class="font-semibold">Nuevo mensaje</span> de Tomas</h6>
        <p class="mb-0 text-xs leading-tight text-slate-400 group-hover:text-slate-700 dark:text-white dark:opacity-80">
          <i class="mr-1 fa fa-clock" aria-hidden="true"></i>
          13 minutes ago
        </p>
      </div>
    </div>
</a>