<div class="w-full max-w-full px-3 flex-0">
    <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
      <div class="overflow-x-auto">
        <table class="items-center w-full mb-0 align-top border-gray-200 text-slate-500 dark:border-white/40">
          <thead class="align-bottom">
            <tr>
              <th class="ml-10 px-6 py-3 font-bold uppercase align-middle bg-transparent border-b border-gray-200 border-solid shadow-none text-xxs tracking-none whitespace-nowrap text-slate-400 opacity-70 dark:border-white/40 dark:text-white dark:opacity-80">Referencia</th>
              <th class="px-6 py-3 pl-2 font-bold uppercase align-middle bg-transparent border-b border-gray-200 border-solid shadow-none text-xxs tracking-none whitespace-nowrap text-slate-400 opacity-70 dark:border-white/40 dark:text-white dark:opacity-80">Descripción</th>
              <th class="px-6 py-3 pl-2 font-bold uppercase align-middle bg-transparent border-b border-gray-200 border-solid shadow-none text-xxs tracking-none whitespace-nowrap text-slate-400 opacity-70 dark:border-white/40 dark:text-white dark:opacity-80">Cantidad</th>
              <th class="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 border-solid shadow-none text-xxs tracking-none whitespace-nowrap text-slate-400 opacity-70 dark:border-white/40 dark:text-white dark:opacity-80">Ubicación</th>
              <th class="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 border-solid shadow-none text-xxs tracking-none whitespace-nowrap text-slate-400 opacity-70 dark:border-white/40 dark:text-white dark:opacity-80">Contenedores</th>
              <th class="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 border-solid shadow-none text-xxs tracking-none whitespace-nowrap text-slate-400 opacity-70 dark:border-white/40 dark:text-white dark:opacity-80">Óptimo</th>
            </tr>
          </thead>
          <tbody class="border-t-2 border-current border-solid dark:border-white/40">
            @for (row of inventoryData; track $index) {
                <tr>
                    <td class="p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40">
                      <div class="flex px-2 py-1">
                        <div>
                          <img class="inline-flex items-center justify-center mr-4 text-sm text-white transition-all duration-200 ease-in-out h-9 w-9 rounded-xl" src="https://skuparts.com/dev-arka/img_seals_and_kits/{{row.ref}}.jpg" alt="avatar image" />
                        </div>
                        <div class="flex flex-col justify-center">
                          <h6 class="mb-0 text-sm leading-normal dark:text-white">{{row.ref}}</h6>
                        </div>
                      </div>
                    </td>
                    <td class="p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40">
                      <p class="mb-0 text-sm leading-normal text-slate-400 dark:text-white/80">{{row.description}}</p>
                    </td>
                    <td class="p-2 align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40">
                      <span class="py-2.2 rounded-1.8 text-sm mr-6 inline-block whitespace-nowrap bg-transparent px-0 text-center align-baseline font-normal leading-none text-white">
                        <i class="rounded-circle mr-1.5 inline-block h-1.5 w-1.5 bg-cyan-500 align-middle"></i>
                        <span class="text-xs leading-tight text-slate-700 dark:text-white">{{row.qty}}</span>
                      </span>
                    </td>
                    <td class="p-2 text-sm leading-normal text-center align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40">
                      <p class="mb-0 text-sm leading-normal text-slate-400 dark:text-white/80">{{row.ubicacion}}</p>
                    </td>
                    <td class="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40">
                      <span class="text-sm leading-normal text-slate-400 dark:text-white/80">{{row.contenedores}}</span>
                    </td>
                    <td class="p-2 text-center align-middle bg-transparent border-b whitespace-nowrap dark:border-white/40">
                      <span class="text-sm leading-normal text-slate-400 dark:text-white/80">{{row.optimo}}</span>
                    </td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
</div>