<div class="w-auto p-10 flex-0">
    <div class="relative flex flex-col -mx-auto min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border table-container">
      <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6">
        <h5 class="mb-0 dark:text-white">Verificación de inventario</h5>
        <p class="mb-0 text-sm leading-normal"></p>
      </div>
      <form action="" class="px-6">
        <div class="table-responsive">
              <table class="table table-flush" datatable id="datatable-search">
                <thead class="thead-light">
                  <tr>
                      <th>Item</th>
                      <th>Referencia</th>
                      <th>Ubicación</th>
                      <th>Cantidad</th>
                      <th></th>
                  </tr>
                </thead>
                <tbody>
                  @for (row of tableContent; track $index) {
                    <tr id="daily-{{row.ref}}">
                        <td>{{$index+1}}</td>
                        <td class="text-sm font-normal leading-normal more-width">{{row.ref}}</td>
                        <td class="text-sm font-normal leading-normal more-width">{{row.ubicacion}}</td>
                        <td class="text-sm font-normal leading-normal more-width">
                          <input
                            id="daily-input-{{row.ref}}"
                            type="number"
                            class="w-16 h-auto"
                            value=9
                          >
                        </td>
                        <td>
                            <button type="button" class="inline-block font-bold leading-normal text-blue-500 text-center align-middle cursor-pointer select-none border border-solid border-blue-500 rounded-lg bg-transparent transition-all ease-in tracking-tight-rem shadow-none px-4 py-1.5 text-xs hover:opacity-75 hover:-translate-y-px active:hover:opacity-75 active:hover:-translate-y-px active:-translate-y-px active:hover:shadow-none active:hover:bg-transparent active:hover:text-blue-500 active:bg-black active:shadow-xs active:text-black"
                              (click)="updateQty(row.ref)" > Validar
                            </button>
                        </td>
                    </tr>
                  }
                </tbody>
              </table>
        </div>
        <div class="flex justify-center pb-5">
          <button type="button" class="mr-3 inline-block px-6 py-3 font-bold text-center bg-gradient-to-tl from-blue-500 to-violet-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-normal text-xs ease-in tracking-tight-rem shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md text-white">Renovar</button>
        </div>
      </form>
    </div>
</div>
