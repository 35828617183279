import { Routes } from '@angular/router';
import { GeneralSummaryComponent } from './domains/dashboard/pages/general-summary/general-summary.component';
import { DashboardInventoryComponent } from './domains/dashboard/pages/dashboard-inventory/dashboard-inventory.component';
import { LoginComponent } from './domains/dashboard/pages/login/login.component';

export const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'dashboard-inventory',
    component: DashboardInventoryComponent
  },
  {
    path: 'dashboard-kits',
    component: GeneralSummaryComponent
  },
  {
    path: 'login',
    component: LoginComponent
  }
];
