import { Component, Input, inject } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';



@Component({
  selector: 'costs-validation',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './costs-validation.component.html',
  styleUrl: './costs-validation.component.scss'
})
export class CostsValidationComponent {
  @Input() tableContent ?: any[];
  private dashboardInventoryService = inject(DashboardService);

  costInputs: FormControl[] = [];

  ngOnInit() {
    this.initializeCostInputs();
  }

  initializeCostInputs() {
    if (this.tableContent) {
      this.tableContent.forEach((item) => {
        this.costInputs.push(new FormControl(item.costo, {
          nonNullable:true,
          validators: [
            Validators.required,
          ]
        }));
      });
    }
  }


  updateCost(ref:string, cost:number){
    this.dashboardInventoryService.updateCost(ref, cost)
      .then(APIresponse=> {
        //Swal.fire(APIresponse.userMessage);
        if(!APIresponse.msg.includes("error")){
          let rowCost = document.getElementById("cost-"+ref);
          rowCost?.classList.add('bg-gradient-to-tl', 'from-emerald-500', 'to-teal-400', 'text-black', 'rounded-xl');
          let button = rowCost?.querySelector('button');
          let input = rowCost?.querySelector('input');
          if(button != null) button.disabled = true;
          if(input != null) input.disabled = true;
        };
        alert(APIresponse.msg);
      }).catch(error=>{
        console.log("error: landing.component 1 "+error);
    });
  }
}
