import { Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'missing-data',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './missing-data.component.html',
  styleUrl: './missing-data.component.scss'
})
export class MissingDataComponent {
  @Input() missingData ?: any[];
  currentDate: Date = new Date();


  addMissingInfo(){
    alert("editando")
  }
}
