<div class="relative flex flex-col min-w-0 break-words bg-white border-0 border-transparent border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
    <div class="flex-auto px-0 pt-0">
      <div class="p-0 overflow-x-auto">
        <table class="items-center w-full mb-0 align-top border-collapse dark:border-white/40 text-slate-500">
          <thead class="align-bottom">
            <tr>
              <th class="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Author</th>
              <th class="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Function</th>
              <th class="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Technology</th>
              <th class="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-collapse shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Employed</th>
              <th class="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-collapse border-solid shadow-none dark:border-white/40 dark:text-white tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex px-2 py-1">
                  <div>
                    <img src="../assets/argon-tailwind/img/team-2.jpg" class="inline-flex items-center justify-center mr-4 text-sm text-white transition-all duration-200 ease-in-out h-9 w-9 rounded-xl" alt="user1" />
                  </div>
                  <div class="flex flex-col justify-center">
                    <h6 class="mb-0 text-xs leading-normal dark:text-white">John Michael</h6>
                    <p class="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">john&#64;creative-tim.com</p>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <p class="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-80">Manager</p>
                <p class="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">Organization</p>
              </td>
              <td class="p-2 text-sm leading-normal text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <span class="bg-emerald-200 text-emerald-600 px-1.75 text-xxs rounded-1 py-1 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none">Online</span>
              </td>
              <td class="p-2 text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <span class="text-xs font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400">23/04/18</span>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <a href="javascript:;" class="text-xs font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400"> Edit </a>
              </td>
            </tr>
            <tr>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex px-2 py-1">
                  <div>
                    <img src="../assets/argon-tailwind/img/team-3.jpg" class="inline-flex items-center justify-center mr-4 text-sm text-white transition-all duration-200 ease-in-out h-9 w-9 rounded-xl" alt="user2" />
                  </div>
                  <div class="flex flex-col justify-center">
                    <h6 class="mb-0 text-xs leading-normal dark:text-white">Alexa Liras</h6>
                    <p class="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">alexa&#64;creative-tim.com</p>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <p class="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-80">Programator</p>
                <p class="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">Developer</p>
              </td>
              <td class="p-2 text-sm leading-normal text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <span class="bg-slate-100 text-slate-600 px-1.75 text-xxs rounded-1 py-1 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none">Offline</span>
              </td>
              <td class="p-2 text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <span class="text-xs font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400">11/01/19</span>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <a href="javascript:;" class="text-xs font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400"> Edit </a>
              </td>
            </tr>
            <tr>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex px-2 py-1">
                  <div>
                    <img src="../assets/argon-tailwind/img/team-4.jpg" class="inline-flex items-center justify-center mr-4 text-sm text-white transition-all duration-200 ease-in-out h-9 w-9 rounded-xl" alt="user3" />
                  </div>
                  <div class="flex flex-col justify-center">
                    <h6 class="mb-0 text-xs leading-normal dark:text-white">Laurent Perrier</h6>
                    <p class="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">laurent&#64;creative-tim.com</p>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <p class="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-80">Executive</p>
                <p class="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">Projects</p>
              </td>
              <td class="p-2 text-sm leading-normal text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <span class="bg-emerald-200 text-emerald-600 px-1.75 text-xxs rounded-1 py-1 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none">Online</span>
              </td>
              <td class="p-2 text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <span class="text-xs font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400">19/09/17</span>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <a href="javascript:;" class="text-xs font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400"> Edit </a>
              </td>
            </tr>
            <tr>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex px-2 py-1">
                  <div>
                    <img src="../assets/argon-tailwind/img/team-3.jpg" class="inline-flex items-center justify-center mr-4 text-sm text-white transition-all duration-200 ease-in-out h-9 w-9 rounded-xl" alt="user4" />
                  </div>
                  <div class="flex flex-col justify-center">
                    <h6 class="mb-0 text-xs leading-normal dark:text-white">Michael Levi</h6>
                    <p class="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">michael&#64;creative-tim.com</p>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <p class="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-80">Programator</p>
                <p class="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">Developer</p>
              </td>
              <td class="p-2 text-sm leading-normal text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <span class="bg-emerald-200 text-emerald-600 px-1.75 text-xxs rounded-1 py-1 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none">Online</span>
              </td>
              <td class="p-2 text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <span class="text-xs font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400">24/12/08</span>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <a href="javascript:;" class="text-xs font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400"> Edit </a>
              </td>
            </tr>
            <tr>
              <td class="p-2 align-middle bg-transparent border-b-0 dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex px-2 py-1">
                  <div>
                    <img src="../assets/argon-tailwind/img/team-2.jpg" class="inline-flex items-center justify-center mr-4 text-sm text-white transition-all duration-200 ease-in-out h-9 w-9 rounded-xl" alt="user5" />
                  </div>
                  <div class="flex flex-col justify-center">
                    <h6 class="mb-0 text-xs leading-normal dark:text-white">Richard Gran</h6>
                    <p class="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">richard&#64;creative-tim.com</p>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b-0 dark:border-white/40 whitespace-nowrap shadow-transparent">
                <p class="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-80">Manager</p>
                <p class="mb-0 text-xs leading-tight dark:text-white dark:opacity-80 text-slate-400">Executive</p>
              </td>
              <td class="p-2 text-sm leading-normal text-center align-middle bg-transparent border-b-0 dark:border-white/40 whitespace-nowrap shadow-transparent">
                <span class="bg-slate-100 text-slate-600 px-1.75 text-xxs rounded-1 py-1 inline-block whitespace-nowrap text-center align-baseline font-bold uppercase leading-none">Offline</span>
              </td>
              <td class="p-2 text-center align-middle bg-transparent border-b-0 dark:border-white/40 whitespace-nowrap shadow-transparent">
                <span class="text-xs font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400">04/10/21</span>
              </td>
              <td class="p-2 align-middle bg-transparent border-b-0 dark:border-white/40 whitespace-nowrap shadow-transparent">
                <a href="javascript:;" class="text-xs font-semibold leading-tight dark:text-white dark:opacity-80 text-slate-400"> Edit </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>