import { Component } from '@angular/core';

@Component({
  selector: 'finance-balance',
  standalone: true,
  imports: [],
  templateUrl: './finance-balance.component.html',
  styleUrl: './finance-balance.component.scss'
})
export class FinanceBalanceComponent {

}
