<div>
  <div class="absolute w-full bg-black dark:hidden min-h-75"></div>
  <sidenav/>
  <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out xl:ml-68 rounded-xl">
      <!-- Navbar -->
      <header-nav/>
      <!--Report-->
      <div class="w-full p-6 pb-0 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full max-w-full px-3 flex-0 lg:w-6/12">
              @for(cardDataCouple of generalData; track i; let i = $index ) {
                @if(i == 0){
                  <div class="flex flex-wrap -mx-3" >
                    @for(cardData of cardDataCouple; track j; let j = $index ) {
                      @if(j%2 == 0){
                        <card-data-inventory class="w-full max-w-full px-3 flex-0 md:w-6/12"
                          [value]="cardData.value"
                          [description]="cardData.description"
                          [metric]="cardData.indicator"
                      />
                      }@else{
                          <card-data-inventory class="w-full max-w-full px-3 mt-6 flex-0 md:mt-0 md:w-6/12"
                            [value]="cardData.value"
                            [description]="cardData.description"
                            [metric]="cardData.indicator"
                          />
                      }
                    }
                  </div>
                }@else{
                  <div class="flex flex-wrap -mx-3 mt-6" >
                    @for(cardData of cardDataCouple; track j; let j = $index ) {
                      @if(j%2 == 0){
                        <card-data-inventory class="w-full max-w-full px-3 flex-0 md:w-6/12"
                          [value]="cardData.value"
                          [description]="cardData.description"
                          [metric]="cardData.indicator"
                      />
                      }@else{
                          <card-data-inventory class="w-full max-w-full px-3 mt-6 flex-0 md:mt-0 md:w-6/12"
                            [value]="cardData.value"
                            [description]="cardData.description"
                            [metric]="cardData.indicator"
                          />
                      }
                    }
                  </div>
                }

              }
            </div>
            <progress-metrics class="w-full max-w-full px-3 flex-0 lg:w-6/12"
              [progressData]="progressBar"
            />
          </div>
      </div>
      <!--CRM-->
      <div class="flex flex-wrap p-6 pb-0 mx-auto">
          <stock-alert class="w-full max-w-full px-3 sm:flex-0 shrink-0 sm:w-6/12"
            [alertStock]="stockAlert"
          />
          <missing-data class="w-full max-w-full px-3 mt-6 sm:flex-0 shrink-0 sm:mt-0 sm:w-6/12"
            [missingData]="missingInformation"
          />
      </div>
      <!--Validations' tables-->
      <div class="flex flex-wrap p-6 pb-0 mx-auto">
        <daily-rectification class="w-full max-w-full px-3 sm:flex-0 shrink-0 sm:w-6/12"
        [tableContent]="dailyValidation"
        />
        <!--
        <costs-validation class="w-full max-w-full px-3 mt-6 sm:flex-0 shrink-0 sm:mt-0 sm:w-6/12"
          [tableContent]="costMissing"
        />
        -->
      </div>
      <!--Table members-->
      <inventary-table class="flex flex-wrap p-6 pb-0 mx-auto mb-5"
        [inventoryData]="inventoryData"
      />
  </main>
</div>
