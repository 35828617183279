import { Injectable, inject } from '@angular/core';
import { ApiHandler } from '../utils/api-handler.utils';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private http = inject(HttpClient);

  getDashboardInventoryData () {
    return this.http.get<any>('https://skuparts.com/dev-arka/restful/index.php/DashboardInventory');

  }

  updateCost(ref:string, cost:number){
    const  url = 'https://skuparts.com/dev-arka/restful/index.php/Cost/info/';
    const body = {
      "ref": ref,
      "new_price": cost,
     };
     return ApiHandler.fetchPost(url, body);

    //return this.http.post<JSON>(url, body);
  }


  updateQty(ref:string, cost:number){
    const  url = 'https://skuparts.com/dev-arka/restful/index.php/Inventory/qty/';
    const body = {
      "ref": ref,
      "qty": cost,
     };
     return ApiHandler.fetchPost(url, body);

    //return this.http.post<JSON>(url, body);
  }


}
