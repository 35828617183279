import { Component, Input } from '@angular/core';

@Component({
  selector: 'progress-metrics',
  standalone: true,
  imports: [],
  templateUrl: './progress-metrics.component.html',
  styleUrl: './progress-metrics.component.scss'
})
export class ProgressMetricsComponent {
  @Input() progressData ?: any[]; 
  colorsArray = [
    {
      color1: 'blue-700',
      color2: 'cyan-500'
    }, 
    {
      color1: 'slate-600',
      color2: 'slate-300'
    },
    {
      color1: 'red-600',
      color2: 'orange-600'
    },
    {
      color1: 'emerald-500',
      color2: 'teal-400'
    },
    {
      color1: 'orange-500',
      color2: 'yellow-500'
    }
  ]
}
