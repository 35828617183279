import { signal,Component, Input,inject } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import Swal from 'sweetalert2';

import { tableContent } from '../../models/tableContent.model';

@Component({
  selector: 'daily-rectification',
  standalone: true,
  imports: [],
  templateUrl: './daily-rectification.component.html',
  styleUrl: './daily-rectification.component.scss'
})
export class DailyRectificationComponent {
  //@Input() tableData ?: tableContent;
  @Input() tableContent : any[]= [];
  qtyInputs : any[]=[];

  private dashboardInventoryService = inject(DashboardService);

  ngOnInit() {
    this.initializeQtyInputs();

  }

  initializeQtyInputs() {
    if (this.tableContent) {
      this.tableContent.forEach((item) => {
        this.qtyInputs.push( item.qty );
      });
    }
  }


  updateQty(ref:string){
    const qty = (document.getElementById("daily-input-"+ref) as HTMLInputElement).value
    if(qty!=null){
      this.dashboardInventoryService.updateQty(ref, parseInt(qty))
      .then(APIresponse=> {

        if(APIresponse.msg.includes("error")){
          let rowDaily = document.getElementById("daily-"+ref);
          if(rowDaily!=null){
            rowDaily.classList.add('bg-gradient-to-tl', 'from-emerald-500', 'to-teal-400', 'text-black', 'rounded-xl');
            let button = rowDaily.querySelector('button');
            let input = rowDaily.querySelector('input');
            if(button != null) button.disabled = true;
            if(input != null) input.disabled = true;
          }
        };
        Swal.fire(APIresponse.msg);

      }).catch(error=>{
        console.log("error: landing.component 1 "+error);
      });
    }


  }



}
