import { Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'inventary-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './inventary-table.component.html',
  styleUrl: './inventary-table.component.scss'
})
export class InventaryTableComponent {
  @Input() inventoryData ?: any[]; 
}
