import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'info-card',
  standalone: true,
  imports: [],
  templateUrl: './info-card.component.html',
  styleUrl: './info-card.component.scss',
})
export class InfoCardComponent {

}
