export class ApiHandler {

  public static fetchPost(url: string, body: object) {

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(body)
    })
    .then(res => res.json()) // parsear a json
    .then(response => {
      return response; // retornar la data
    });

  }

  public static fetchGet(url:string){
      return fetch(url)
      .then(res => res.json())
      .then(data => {
        return data;
      });
  }

}
