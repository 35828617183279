import { Component } from '@angular/core';

@Component({
  selector: 'roles-section',
  standalone: true,
  imports: [],
  templateUrl: './roles-section.component.html',
  styleUrl: './roles-section.component.scss'
})
export class RolesSectionComponent {

}
