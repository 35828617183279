<div class="relative flex flex-col h-full min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
    <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6">
      <h5 class="mb-0 capitalize dark:text-white">Progress track</h5>
    </div>
    <div class="flex-auto p-6 pt-0">
      <ul class="flex flex-col pl-0 mb-0 rounded-none">
        <li class="border-black/12.5 rounded-t-inherit relative block border-b border-solid py-2 px-0 text-inherit">
          <div class="flex flex-wrap items-center -mx-3">
            <div class="w-auto max-w-full px-3 flex-0">
              <a href="javascript:;" class="inline-flex items-center justify-center w-12 h-12 text-base text-white transition-all duration-200 ease-in-out leading-inherit rounded-circle">
                <img class="w-full rounded-circle" src="../assets/argon-tailwind/img/small-logos/logo-jira.svg" alt="Image placeholder" />
              </a>
            </div>
            <div class="w-full max-w-full px-3 flex-1-0">
              <h6 class="dark:text-white/80">React Material Dashboard</h6>
              <div class="mb-0 h-[3px] text-xs leading-inherit flex overflow-visible rounded-lg bg-gray-200">
                <div class="transition-width duration-600 ease rounded-1.5 flex h-auto flex-col justify-center overflow-hidden whitespace-nowrap bg-black text-center text-white w-9/10"></div>
              </div>
            </div>
          </div>
        </li>
        <li class="border-black/12.5 relative block border-b border-t-0 border-solid py-2 px-0 text-inherit">
          <div class="flex flex-wrap items-center -mx-3">
            <div class="w-auto max-w-full px-3 flex-0">
              <a href="javascript:;" class="inline-flex items-center justify-center w-12 h-12 text-base text-white transition-all duration-200 ease-in-out leading-inherit rounded-circle">
                <img class="w-full rounded-circle" src="../assets/argon-tailwind/img/small-logos/logo-asana.svg" alt="Image placeholder" />
              </a>
            </div>
            <div class="w-full max-w-full px-3 flex-1-0">
              <h6 class="dark:text-white/80">Argon Design System</h6>
              <div class="mb-0 h-[3px] text-xs leading-inherit flex overflow-visible rounded-lg bg-gray-200">
                <div class="transition-width duration-600 ease rounded-1.5 flex h-auto flex-col justify-center overflow-hidden whitespace-nowrap bg-orange-500 text-center text-white w-3/5"></div>
              </div>
            </div>
          </div>
        </li>
        <li class="border-black/12.5 relative block border-b border-t-0 border-solid py-2 px-0 text-inherit">
          <div class="flex flex-wrap items-center -mx-3">
            <div class="w-auto max-w-full px-3 flex-0">
              <a href="javascript:;" class="inline-flex items-center justify-center w-12 h-12 text-base text-white transition-all duration-200 ease-in-out leading-inherit rounded-circle">
                <img class="w-full rounded-circle" src="../assets/argon-tailwind/img/small-logos/logo-spotify.svg" alt="Image placeholder" />
              </a>
            </div>
            <div class="w-full max-w-full px-3 flex-1-0">
              <h6 class="dark:text-white/80">VueJs Now UI Kit PRO</h6>
              <div class="mb-0 h-[3px] text-xs leading-inherit flex overflow-visible rounded-lg bg-gray-200">
                <div class="transition-width duration-600 ease rounded-1.5 flex h-auto flex-col justify-center overflow-hidden whitespace-nowrap bg-emerald-500 text-center text-white w-full"></div>
              </div>
            </div>
          </div>
        </li>
        <li class="border-black/12.5 rounded-b-inherit relative block py-2 px-0 text-inherit">
          <div class="flex flex-wrap items-center -mx-3">
            <div class="w-auto max-w-full px-3 flex-0">
              <a href="javascript:;" class="inline-flex items-center justify-center w-12 h-12 text-base text-white transition-all duration-200 ease-in-out leading-inherit rounded-circle">
                <img class="w-full rounded-circle" src="../assets/argon-tailwind/img/small-logos/bootstrap.svg" alt="Image placeholder" />
              </a>
            </div>
            <div class="w-full max-w-full px-3 flex-1-0">
              <h6 class="dark:text-white/80">Soft UI Dashboard</h6>
              <div class="mb-0 h-[3px] text-xs leading-inherit flex overflow-visible rounded-lg bg-gray-200">
                <div class="transition-width duration-600 ease rounded-1.5 flex h-auto flex-col justify-center overflow-hidden whitespace-nowrap bg-black text-center text-white w-72/100"></div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>