import { Component, inject } from '@angular/core';
import { SidenavComponent } from '../../../shared/components/sidenav/sidenav.component';
import { HeaderNavComponent } from '../../../shared/components/header-nav/header-nav.component';
import { CardDataInventoryComponent } from '../../../shared/components/card-data-inventory/card-data-inventory.component';
import { DailyRectificationComponent } from '../../../shared/components/daily-rectification/daily-rectification.component';
import { CostsValidationComponent } from '../../../shared/components/costs-validation/costs-validation.component';
import { InventaryTableComponent } from '../../../shared/components/inventary-table/inventary-table.component';
import { MissingDataComponent} from '../../../shared/components/missing-data/missing-data.component';
import { StockAlertComponent} from '../../../shared/components/stock-alert/stock-alert.component';
import { ProgressMetricsComponent} from '../../../shared/components/progress-metrics/progress-metrics.component';
import { DashboardService } from '../../../shared/services/dashboard.service';
import { cardData } from '../../../shared/models/dashboardInfo.model';

@Component({
  selector: 'app-dashboard-inventory',
  standalone: true,
  imports: [SidenavComponent, HeaderNavComponent, CardDataInventoryComponent, DailyRectificationComponent, CostsValidationComponent, InventaryTableComponent, MissingDataComponent, StockAlertComponent, ProgressMetricsComponent],
  templateUrl: './dashboard-inventory.component.html',
  styleUrl: './dashboard-inventory.component.scss'
})
export class DashboardInventoryComponent {
  private dashboardInventoryService = inject(DashboardService);
  message ?: string;
  generalData ?: cardData[][];
  costMissing ?: [];
  stockAlert ?: [];
  missingInformation ?: [];
  dailyValidation: any[] = [];
  inventoryData ?: [];
  progressBar ?: [];

  ngOnInit() {
    this.dashboardInventoryService.getDashboardInventoryData()
    .subscribe({
      next: (dashboardInventoryData) =>{
        console.log(dashboardInventoryData);
        this.message = dashboardInventoryData.message;
        this.generalData = dashboardInventoryData.content.general_data.reduce((result:any, value:any, index:any) => {
          if (index % 2 === 0) {
            result.push([value]);
          } else {
            result[result.length - 1].push(value);
          }
          return result;
        }, []);
        this.costMissing = dashboardInventoryData.content.cost_missing;
        this.stockAlert = dashboardInventoryData.content.stock_alert;
        this.dailyValidation = dashboardInventoryData.content.daily_rectification;
        this.missingInformation = dashboardInventoryData.content.missing_infomation;
        this.inventoryData = dashboardInventoryData.content.inventory;
        this.progressBar = dashboardInventoryData.content.progressbar;

        console.log("--->" + JSON.stringify(this.dailyValidation, null, 2));
      },
      error: () => {
        console.log('ERROR!');
      }
    })




  }


}
