import { Component } from '@angular/core';
import { SidenavComponent } from '../../../shared/components/sidenav/sidenav.component';
import { HeaderNavComponent } from '../../../shared/components/header-nav/header-nav.component';
import { InfoCardComponent } from '../../../shared/components/info-card/info-card.component';
import { GraphicInfoComponent } from '../../../shared/components/graphic-info/graphic-info.component';
import { SlideInfoComponent } from '../../../shared/components/slide-info/slide-info.component';
import { TeamMembersComponent } from '../../../shared/components/team-members/team-members.component';
import { ToDoListComponent } from '../../../shared/components/to-do-list/to-do-list.component';
import { ProgressTrackComponent } from '../../../shared/components/progress-track/progress-track.component';
import { ActivityMembersComponent } from '../../../shared/components/activity-members/activity-members.component';
import { ProjectStatusComponent } from '../../../shared/components/project-status/project-status.component';
import { FinanceBalanceComponent } from '../../../shared/components/finance-balance/finance-balance.component';
import { CountrySalesComponent } from '../../../shared/components/country-sales/country-sales.component';
import { AdressCardComponent } from '../../../shared/components/adress-card/adress-card.component';
import { CategoriesSectionComponent } from '../../../shared/components/categories-section/categories-section.component';
import { RolesSectionComponent } from '../../../shared/components/roles-section/roles-section.component';

@Component({
  selector: 'general-summary',
  standalone: true,
  imports: [SidenavComponent, HeaderNavComponent, InfoCardComponent, GraphicInfoComponent, SlideInfoComponent, TeamMembersComponent, ToDoListComponent, ProgressTrackComponent, ActivityMembersComponent, ProjectStatusComponent, FinanceBalanceComponent, CountrySalesComponent, AdressCardComponent, CategoriesSectionComponent, RolesSectionComponent],
  templateUrl: './general-summary.component.html',
  styleUrl: './general-summary.component.scss'
})
export class GeneralSummaryComponent {

}
