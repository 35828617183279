import { Component } from '@angular/core';

@Component({
  selector: 'country-sales',
  standalone: true,
  imports: [],
  templateUrl: './country-sales.component.html',
  styleUrl: './country-sales.component.scss'
})
export class CountrySalesComponent {

}
