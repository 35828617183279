<div class="dark:bg-slate-850 dark:shadow-dark-xl shadow-xl bg-cover relative flex min-w-0 flex-col break-words rounded-2xl border-0  bg-clip-border ">
    <span class="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-zinc-800 to-zinc-700 dark:bg-gradient-to-tl dark:from-slate-750 dark:to-gray-850 rounded-2xl opacity-80"> </span>
    <div class="relative flex-auto p-4 h-full">
      <div class="flex flex-wrap -mx-3">
        <div class="w-8/12 max-w-full px-3 text-left flex-0">
          <div class="inline-block w-12 h-12 text-center text-black bg-white bg-center rounded-lg shadow-2xl fill-current stroke-none">
            <i class="ni leading-none {{icon}} bg-gradient-to-tl from-zinc-800 to-zinc-700 text-lg relative top-3.5 z-10 bg-clip-text text-transparent"></i>
          </div>
          <h5 class="mt-4 mb-0 font-bold text-white">{{value}}</h5>
          <span class="text-sm leading-normal text-white">{{description}}</span>
        </div>
        <div class="w-4/12 h-full max-w-full px-3 flex flex-col justify-end">
          <p class="mt-auto mb-0 text-sm font-bold leading-normal text-right text-white dark:text-white/60 h-full">{{metric}}</p>
        </div>
      </div>
    </div>
</div>
