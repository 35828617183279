<main class="mt-0 transition-all duration-200 ease-in-out">
    <div class="pb-0 pt-0 h-full min-h-screen items-start p-0 relative overflow-hidden flex bg-cover bg-center bg-arka_pattern">
      <span class="absolute top-0 left-0 w-full h-full bg-center bg-cover opacity-60 bg-gradient-to-tl from-zinc-800 to-zinc-700"></span>
      <div class="container">
        <div class="flex flex-wrap justify-center mt-48 -mx-3 lg:mt-48 md:mt-56">
          <div class="w-full max-w-full px-6 mx-auto shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
            <div class="relative z-0 flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <div class="text-center border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6">
                <h5 class="mt-2 mb-4">Iniciar sesión</h5>
                <div class="text-center">
                  <a href="javascript:;" class="inline-block px-8 py-2 text-xs font-bold leading-normal text-center align-middle transition-all ease-in bg-transparent rounded-lg shadow-md cursor-pointer select-none active:-translate-y-px hover:-translate-y-px hover:shadow-xs text-slate-500 tracking-tight-rem">
                    <img src="assets/argon-tailwind/img/logos/github.svg" alt="social icon" class="w-3/10">
                    Github
                  </a>
                  <a href="javascript:;" class="inline-block px-8 py-2 text-xs font-bold leading-normal text-center align-middle transition-all ease-in bg-transparent rounded-lg shadow-md cursor-pointer select-none active:-translate-y-px hover:-translate-y-px hover:shadow-xs text-slate-500 tracking-tight-rem">
                    <img src="assets/argon-tailwind/img/logos/google.svg" alt="social icon" class="w-3/10">
                    Google
                  </a>
                </div>
              </div>
              <div class="flex-auto p-12 pt-0 pb-6 text-center">
                <div class="mb-6 text-center text-slate-500">
                  <small>O entra con tus credenciales</small>
                </div>
                <form role="form text-left">
                  <div class="mb-4">
                    <input type="email" class="text-sm focus:shadow-primary-outline dark:bg-slate-850 placeholder:text-gray-500 dark:placeholder:text-white/80 dark:text-white/80 leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow" placeholder="Email" aria-label="Email" aria-describedby="email-addon" />
                  </div>
                  <div class="mb-4">
                    <input type="password" class="text-sm focus:shadow-primary-outline dark:bg-slate-850 placeholder:text-gray-500 dark:placeholder:text-white/80 dark:text-white/80 leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow" placeholder="Password" aria-label="Password" aria-describedby="password-addon" />
                  </div>
                  <div class="min-h-6 mb-0.5 block pl-12 text-left">
                    <input id="rememberMe" class="mt-0.5 rounded-10 duration-250 ease-in-out after:rounded-circle after:shadow-2xl after:duration-250 checked:after:translate-x-5.3 h-5 relative float-left -ml-12 w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-blue-500/95 checked:bg-blue-500/95 checked:bg-none checked:bg-right" type="checkbox"/>
                    <label class="ml-1 text-sm font-normal cursor-pointer select-none text-slate-700" for="rememberMe">Recuerdame</label>
                  </div>
                  <div class="text-center">
                    <a href="/dashboard-inventory">
                        <button type="button"class="inline-block w-full px-5 py-2.5 mt-6 mb-2 text-sm font-700 text-center text-black align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer active:-translate-y-px active:hover:text-white active:text-black hover:-translate-y-px hover:shadow-xs leading-normal tracking-tight-rem bg-150 bg-x-25 bg-primary hover:border-blue-500 hover:bg-blue-500 hover:text-white">Iniciar sesión</button>
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>