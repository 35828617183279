<div class="relative flex flex-col h-full min-w-0 break-words bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
    <div class="flex items-center px-6 py-4 border-b border-solid rounded-t-2xl border-b-slate-100">
      <div class="flex items-center">
        <a href="javascript:;">
          <img src="../assets/argon-tailwind/img/team-4.jpg" alt="profile-image" class="inline-flex items-center justify-center w-12 h-12 text-base text-white transition-all duration-200 ease-in-out rounded-xl" />
        </a>
        <div class="mx-4">
          <a href="javascript:;" class="text-sm leading-normal text-slate-700 dark:text-white">John Snow</a>
          <small class="block text-slate-500">3 days ago</small>
        </div>
      </div>
      <div class="ml-auto text-right">
        <button class="inline-block px-8 py-2 mb-0 text-xs font-bold text-center text-white uppercase align-middle transition-all ease-in bg-transparent border-0 rounded-lg shadow-md cursor-pointer hover:-translate-y-px hover:shadow-xs active:opacity-85 tracking-tight-rem leading-pro bg-150 bg-x-25 bg-gradient-to-tl from-blue-500 to-violet-500">
          <i class="pr-2 fas fa-plus text-3xs"></i>
          Follow
        </button>
      </div>
    </div>
    <div class="flex-auto p-6">
      <p class="mb-6 dark:text-white dark:opacity-60">Personal profiles are the perfect way for you to grab their attention and persuade recruiters to continue reading your CV because you’re telling them from the off exactly why they should hire you.</p>
      <img src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/activity-feed.jpg" alt="Image meeting" class="h-auto max-w-full shadow-3xl rounded-xl" />
      <div class="flex flex-wrap items-center px-2 mt-6 mb-2 -mx-3">
        <div class="w-full max-w-full px-3 sm:flex-0 shrink-0 sm:w-6/12">
          <div class="flex">
            <div class="flex items-center">
              <i class="mr-1 leading-none cursor-pointer ni ni-like-2"></i>
              <span class="mr-4 text-sm leading-normal">150</span>
            </div>
            <div class="flex items-center">
              <i class="mr-1 leading-none cursor-pointer ni ni-chat-round"></i>
              <span class="mr-4 text-sm leading-normal">36</span>
            </div>
            <div class="flex items-center">
              <i class="mr-1 leading-none cursor-pointer ni ni-curved-next"></i>
              <span class="mr-4 text-sm leading-normal">12</span>
            </div>
          </div>
        </div>
        <div class="hidden w-full max-w-full px-3 sm:flex-0 shrink-0 sm:block sm:w-6/12">
          <div class="flex items-center sm:justify-end">
            <div class="flex items-center">
              <a href="javascript:;" class="inline-flex items-center justify-center w-6 h-6 text-xs text-white transition-all duration-200 ease-in-out leading-inherit">
                <img src="../assets/argon-tailwind/img/team-5.jpg" alt="Image placeholder" class="w-full rounded-circle" />
              </a>
              <a href="javascript:;" class="inline-flex items-center justify-center w-6 h-6 text-xs text-white transition-all duration-200 ease-in-out leading-inherit">
                <img src="../assets/argon-tailwind/img/team-2.jpg" alt="Image placeholder" class="w-full rounded-circle" />
              </a>
              <a href="javascript:;" class="inline-flex items-center justify-center w-6 h-6 text-xs text-white transition-all duration-200 ease-in-out leading-inherit">
                <img src="../assets/argon-tailwind/img/team-1.jpg" alt="Image placeholder" class="w-full rounded-circle" />
              </a>
            </div>
            <small class="pl-2 font-semibold">and 30+ more</small>
          </div>
        </div>
        <hr class="w-full h-px max-w-full px-3 my-4 dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent shrink-0" />
      </div>
      <div class="mb-1">
        <div class="flex">
          <div class="shrink-0">
            <img src="../assets/argon-tailwind/img/bruce-mars.jpg" alt="Image placeholder" class="inline-flex items-center justify-center w-12 h-12 text-base text-white transition-all duration-200 ease-in-out rounded-circle" />
          </div>
          <div class="ml-4 grow">
            <h5 class="mt-0 mb-2 dark:text-white">Michael Lewis</h5>
            <p class="text-sm leading-normal dark:text-white dark:opacity-60">I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves!</p>
            <div class="flex">
              <div>
                <i class="mr-1 leading-none cursor-pointer ni ni-like-2"></i>
              </div>
              <span class="mr-2 text-sm leading-normal">3 likes</span>
              <div>
                <i class="mr-1 leading-none cursor-pointer ni ni-curved-next"></i>
              </div>
              <span class="mr-2 text-sm leading-normal">2 shares</span>
            </div>
          </div>
        </div>
        <div class="flex mt-4">
          <div class="shrink-0">
            <img src="../assets/argon-tailwind/img/team-5.jpg" alt="Image placeholder" class="inline-flex items-center justify-center w-12 h-12 text-base text-white transition-all duration-200 ease-in-out rounded-circle" />
          </div>
          <div class="ml-4 grow">
            <h5 class="mt-0 mb-2 dark:text-white">Jessica Stones</h5>
            <p class="text-sm leading-normal dark:text-white dark:opacity-60">Society has put up so many boundaries, so many limitations on what’s right and wrong that it’s almost impossible to get a pure thought out. It’s like a little kid, a little boy.</p>
            <div class="flex">
              <div>
                <i class="mr-1 leading-none cursor-pointer ni ni-like-2"></i>
              </div>
              <span class="mr-2 text-sm leading-normal">10 likes</span>
              <div>
                <i class="mr-1 leading-none cursor-pointer ni ni-curved-next"></i>
              </div>
              <span class="mr-2 text-sm leading-normal">1 share</span>
            </div>
          </div>
        </div>
        <div class="flex mt-6">
          <div class="shrink-0">
            <img src="../assets/argon-tailwind/img/team-4.jpg" alt="Image placeholder" class="inline-flex items-center justify-center w-12 h-12 mr-4 text-base text-white transition-all duration-200 ease-in-out rounded-circle" />
          </div>
          <div class="my-auto grow">
            <form>
              <textarea rows="1" placeholder="Write your comment" class="focus:shadow-primary-outline dark:bg-slate-850 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 ease min-h-unset block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"></textarea>
            </form>
          </div>
        </div>
      </div>
    </div>
</div>