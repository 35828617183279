<aside mini="false" class="fixed inset-y-0 left-0 flex-wrap items-center justify-between block w-full p-0 my-4 overflow-y-auto transition-all duration-200 ease-in-out -translate-x-full bg-white border-0 shadow-none xl:ml-6 dark:bg-slate-850 z-990 max-w-64 rounded-2xl xl:translate-x-0" id="sidenav-main">
  <!-- header -->

  <div class="h-20">
    <!-- x i -->
    <i class="absolute top-0 right-0 p-4 opacity-50 cursor-pointer fas fa-times text-slate-400 dark:text-white xl:hidden" aria-hidden="true" sidenav-close-btn></i>

    <a class="block px-8 py-6 m-0 text-sm whitespace-nowrap text-slate-700 dark:text-white" href=" https://demos.creative-tim.com/argon-dashboard-pro-tailwind/pages/dashboards/default.html " target="_blank">
      <img src="../assets/images/skuLogo2.png" class="inline-block h-full max-w-full transition-all duration-200 ease-in-out max-h-8 dark:hidden" alt="main_logo" />
      <img src="../assets/images/skuLogo-dark.png" class="hidden h-full max-w-full transition-all duration-200 ease-in-out max-h-8 dark:inline-block" alt="main_logo" />

      <span class="ml-1 font-semibold transition-all duration-200 ease-in-out">VENTAPESADA.COM</span>

    </a>
  </div>

  <!-- //---------hr----------// -->

  <hr class="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />

  <div class="items-center block w-full h-auto grow basis-full" id="sidenav-collapse-main">
    <!-- primary list  -->

    <ul class="flex flex-col pl-0 mb-0 list-none">
      <!-- primary list item -->

      <li class="mt-0.5 w-full">
        <!-- primary anchor  -->

        <a active_primary collapse_trigger="primary" href="javascript:;" class="after:ease-in-out after:font-awesome-5-free ease-in-out text-sm leading-default py-2.7 my-0 mx-2 flex items-center whitespace-nowrap rounded-lg bg-black/30 px-4 font-semibold text-slate-700 transition-all after:ml-auto after:inline-block after:rotate-180 after:font-bold dark:after:text-white after:text-slate-800 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-80" aria-expanded="true">
          <!-- big anchor expandable -->
          <div class="stroke-none flex h-8 w-8 items-center justify-center rounded-lg bg-center fill-current p-2.5 text-center text-black">
            <!-- icon -->
            <i class="text-sm leading-normal text-blue-500 ni ni-shop"></i>
          </div>

          <!-- primary span -->

          <span class="ml-1 duration-300 opacity-100 pointer-events-none ease text-slate-700 dark:text-white">Dashboards</span>
        </a>

        <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out" id="dashboardsExamples">
          <!-- primary collapsable list -->
          <ul class="flex flex-wrap mb-0 ml-6 list-none transition-all duration-200 ease-in-out">
            <!-- medium list item  -->

            <li class="w-full">
              <!-- medium a -->
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="/dashboard-kits">
                <!-- mini span -->
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> K </span>

                <!-- estended span -->
                <span class="transition-all duration-100 pointer-events-none ease"> Kits </span>
              </a>
            </li>

            <li class="w-full">
              <!-- medium a -->
              <a active_page active_secondary class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap rounded-lg bg-transparent pr-4 font-semibold text-slate-800 shadow-none transition-colors  dark:text-white dark:opacity-100 " href="/dashboard-inventory">
                <!-- mini span -->
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> I </span>

                <!-- estended span -->
                <span class="transition-all duration-100 pointer-events-none ease"> Inventario </span>
              </a>
            </li>

          </ul>
        </div>
      </li>

      <li class="w-full mt-4">
        <h6 class="pl-6 ml-2 text-xs font-bold leading-tight uppercase opacity-60 dark:text-white">Módulos</h6>
      </li>

      <li class="mt-0.5 w-full">
        <a collapse_trigger="primary" href="javascript:;" class="ease-in-out text-sm leading-default py-2.7 active after:ease-in-out after:font-awesome-5-free my-0 mx-2 flex items-center whitespace-nowrap px-4 font-medium text-slate-500 shadow-none transition-colors after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-80 dark:after:text-white/50 dark:after:text-white" aria-controls="pagesExamples" role="button" aria-expanded="false">
          <div class="stroke-none flex h-8 w-8 items-center justify-center rounded-lg bg-center fill-current p-2.5 text-center text-black">
            <i class="text-sm leading-normal text-orange-500 ni ni-ungroup"></i>
          </div>
          <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Inventario</span>
        </a>

        <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="pagesExamples">
          <ul class="flex flex-wrap mb-0 ml-6 list-none transition-all duration-200 ease-in-out">
            <li class="w-full">
              <a collapse_trigger="secondary" class="after:ease-in-out after:font-awesome-5-free ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-60  dark:after:text-white/50 dark:after:text-white" aria-expanded="false" href="javascript:;">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> P </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Perfil <b class="caret"></b></span>
              </a>

              <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="profileExample">
                <ul class="flex flex-col flex-wrap pl-0 mb-0 list-none transition-all duration-200 ease-in-out">
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/pages/profile/overview.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> P </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Profile Overview </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/pages/profile/teams.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> T </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Temas </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/pages/profile/projects.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> A </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Todos los proyectos </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="w-full">
              <a collapse_trigger="secondary" class="after:ease-in-out after:font-awesome-5-free ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-60  dark:after:text-white/50 dark:after:text-white" aria-expanded="false" href="javascript:;">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> U </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Usuarios <b class="caret"></b></span>
              </a>

              <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="usersExample">
                <ul class="flex flex-col flex-wrap pl-0 mb-0 list-none transition-all duration-200 ease-in-out">
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/pages/users/reports.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> R </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Reportes </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/pages/users/new-user.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> N </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Nuevo Usuario</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="w-full">
              <a collapse_trigger="secondary" class="after:ease-in-out after:font-awesome-5-free ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-60  dark:after:text-white/50 dark:after:text-white" aria-expanded="false" href="javascript:;">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> A </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Cuenta <b class="caret"></b></span>
              </a>

              <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="accountExample">
                <ul class="flex flex-col flex-wrap pl-0 mb-0 list-none transition-all duration-200 ease-in-out">
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/pages/account/settings.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> S </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Ajustes </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/pages/account/billing.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> B </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Billing </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/pages/account/invoice.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> I </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Invoice </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/pages/account/security.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> S </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Seguridad </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="w-full">
              <a collapse_trigger="secondary" class="after:ease-in-out after:font-awesome-5-free ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-60  dark:after:text-white/50 dark:after:text-white" aria-expanded="false" href="javascript:;">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> P </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Proyectos <b class="caret"></b></span>
              </a>

              <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="projectsExample">
                <ul class="flex flex-col flex-wrap pl-0 mb-0 list-none transition-all duration-200 ease-in-out">
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/pages/projects/general.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> G </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> General </span>
                    </a>
                  </li>
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/pages/projects/timeline.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> T </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Timeline </span>
                    </a>
                  </li>
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/pages/projects/new-project.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> N </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Nuevo Proyecto </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/pages/pricing-page.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> P </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Pricing Page </span>
              </a>
            </li>

            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/pages/messages.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> M </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Mensajes </span>
              </a>
            </li>

            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/pages/rtl-page.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> R </span>
                <span class="transition-all duration-100 pointer-events-none ease"> RTL </span>
              </a>
            </li>

            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/pages/widgets.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> W </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Widgets </span>
              </a>
            </li>

            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/pages/charts.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> C </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Charts </span>
              </a>
            </li>

            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/pages/sweet-alerts.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> S </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Sweet Alerts </span>
              </a>
            </li>

            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/pages/notifications.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> N </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Notificaciones </span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <!-- Applications -->

      <li class="mt-0.5 w-full">
        <a collapse_trigger="primary" href="javascript:;" class="ease-in-out text-sm leading-default py-2.7 active after:ease-in-out after:font-awesome-5-free my-0 mx-2 flex items-center whitespace-nowrap px-4 font-medium text-slate-500 shadow-none transition-colors after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-80 dark:after:text-white/50 dark:after:text-white" aria-controls="applicationsExamples" role="button" aria-expanded="false">
          <div class="stroke-none flex h-8 w-8 items-center justify-center rounded-lg bg-center fill-current p-2.5 text-center text-black">
            <i class="text-sm leading-normal ni ni-ui-04 text-cyan-500"></i>
          </div>

          <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Ordenes</span>
        </a>
        <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="applicationsExamples">
          <ul class="flex flex-wrap mb-0 ml-6 list-none transition-all duration-200 ease-in-out">
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/kanban.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> K </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Kanban </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/wizard.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> W </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Wizard </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/datatables.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> D </span>
                <span class="transition-all duration-100 pointer-events-none ease"> DataTables </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/calendar.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> C </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Calendar </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/analytics.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> A </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Analytics </span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <!-- Ecommerce  -->

      <li class="mt-0.5 w-full">
        <a collapse_trigger="primary" href="javascript:;" class="ease-in-out text-sm leading-default py-2.7 active after:ease-in-out after:font-awesome-5-free my-0 mx-2 flex items-center whitespace-nowrap px-4 font-medium text-slate-500 shadow-none transition-colors after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-80 dark:after:text-white/50 dark:after:text-white" aria-controls="ecommerceExamples" role="button" aria-expanded="false">
          <div class="stroke-none flex h-8 w-8 items-center justify-center rounded-lg bg-center fill-current p-2.5 text-center text-black">
            <i class="text-sm leading-normal ni ni-archive-2 text-emerald-500"></i>
          </div>

          <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Tienda</span>
        </a>

        <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="ecommerceExamples">
          <ul class="flex flex-wrap mb-0 ml-6 list-none transition-all duration-200 ease-in-out">
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/ecommerce/overview.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> O </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Overview </span>
              </a>
            </li>

            <li class="w-full">
              <a collapse_trigger="secondary" class="after:ease-in-out after:font-awesome-5-free ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-60  dark:after:text-white/50 dark:after:text-white" aria-expanded="false" href="javascript:;">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> P </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Products <b class="caret"></b></span>
              </a>

              <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="productsExample">
                <ul class="flex flex-col flex-wrap pl-0 mb-0 list-none transition-all duration-200 ease-in-out">
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/ecommerce/products/new-product.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> N </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> New Product </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/ecommerce/products/edit-product.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> E </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Edit Product </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/ecommerce/products/product-page.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> P </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Product Page </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/ecommerce/products/products-list.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> P </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Products List </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="w-full">
              <a collapse_trigger="secondary" class="after:ease-in-out after:font-awesome-5-free ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-60  dark:after:text-white/50 dark:after:text-white" aria-expanded="false" href="javascript:;">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> O </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Orders <b class="caret"></b></span>
              </a>

              <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="ordersExample">
                <ul class="flex flex-col flex-wrap pl-0 mb-0 list-none transition-all duration-200 ease-in-out">
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/ecommerce/orders/list.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> O </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Order List </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/ecommerce/orders/details.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> O </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Order Details </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/ecommerce/referral.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> R </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Referral </span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <li class="mt-0.5 w-full">
        <a collapse_trigger="primary" href="javascript:;" class="ease-in-out text-sm leading-default py-2.7 active after:ease-in-out after:font-awesome-5-free my-0 mx-2 flex items-center whitespace-nowrap px-4 font-medium text-slate-500 shadow-none transition-colors after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-80 dark:after:text-white/50 dark:after:text-white" aria-controls="authExamples" role="button" aria-expanded="false">
          <div class="stroke-none flex h-8 w-8 items-center justify-center rounded-lg bg-center fill-current p-2.5 text-center text-black">
            <i class="text-sm leading-normal text-red-600 ni ni-single-copy-04"></i>
          </div>

          <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Bodega</span>
        </a>

        <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="authExamples">
          <ul class="flex flex-wrap mb-0 ml-6 list-none transition-all duration-200 ease-in-out">
            <li class="w-full">
              <a collapse_trigger="secondary" class="after:ease-in-out after:font-awesome-5-free ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-60  dark:after:text-white/50 dark:after:text-white" aria-expanded="false" href="javascript:;">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> S </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Sign In <b class="caret"></b></span>
              </a>

              <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="signinExample">
                <ul class="flex flex-col flex-wrap pl-0 mb-0 list-none transition-all duration-200 ease-in-out">
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/signin/basic.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> B </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Basic </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/signin/cover.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> C </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Cover </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/signin/illustration.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> I </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Illustration </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="w-full">
              <a collapse_trigger="secondary" class="after:ease-in-out after:font-awesome-5-free ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-60  dark:after:text-white/50 dark:after:text-white" aria-expanded="false" href="javascript:;">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> S </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Sign Up <b class="caret"></b></span>
              </a>

              <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="signupExample">
                <ul class="flex flex-col flex-wrap pl-0 mb-0 list-none transition-all duration-200 ease-in-out">
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/signup/basic.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> B </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Basic </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/signup/cover.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> C </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Cover </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/signup/illustration.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> I </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Illustration </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li class="w-full">
              <a collapse_trigger="secondary" class="after:ease-in-out after:font-awesome-5-free ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-60  dark:after:text-white/50 dark:after:text-white" aria-expanded="false" href="javascript:;">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> R </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Reset Password <b class="caret"></b></span>
              </a>

              <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="resetExample">
                <ul class="flex flex-col flex-wrap pl-0 mb-0 list-none transition-all duration-200 ease-in-out">
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/reset/basic.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> B </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Basic </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/reset/cover.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> C </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Cover </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/reset/illustration.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> I </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Illustration </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="w-full">
              <a collapse_trigger="secondary" class="after:ease-in-out after:font-awesome-5-free ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-60  dark:after:text-white/50 dark:after:text-white" aria-expanded="false" href="javascript:;">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> L </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Lock <b class="caret"></b></span>
              </a>

              <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="lockExample">
                <ul class="flex flex-col flex-wrap pl-0 mb-0 list-none transition-all duration-200 ease-in-out">
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/lock/basic.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> B </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Basic </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/lock/cover.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> C </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Cover </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/lock/illustration.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> I </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Illustration </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="w-full">
              <a collapse_trigger="secondary" class="after:ease-in-out after:font-awesome-5-free ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-60  dark:after:text-white/50 dark:after:text-white" aria-expanded="false" href="javascript:;">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> 2 </span>
                <span class="transition-all duration-100 pointer-events-none ease"> 2-Step Verification <b class="caret"></b></span>
              </a>

              <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="StepExample">
                <ul class="flex flex-col flex-wrap pl-0 mb-0 list-none transition-all duration-200 ease-in-out">
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/verification/basic.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> B </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Basic </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/verification/cover.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> C </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Cover </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/verification/illustration.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> I </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Illustration </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="w-full">
              <a collapse_trigger="secondary" class="after:ease-in-out after:font-awesome-5-free ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-60  dark:after:text-white/50 dark:after:text-white" aria-expanded="false" href="javascript:;">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> E </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Error <b class="caret"></b></span>
              </a>

              <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="errorExample">
                <ul class="flex flex-col flex-wrap pl-0 mb-0 list-none transition-all duration-200 ease-in-out">
                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/error/404.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> E </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Error 404 </span>
                    </a>
                  </li>

                  <li class="w-full">
                    <a class="ease-in-out py-2.7 ml-5.4 pl-4 text-3.4 relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors dark:text-white dark:opacity-60" href="./pages/authentication/error/500.html">
                      <span class="w-0 text-xs leading-tight text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> E </span>
                      <span class="transition-all duration-100 pointer-events-none ease"> Error 500 </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li>


      <li class="mt-0.5 w-full">
        <a collapse_trigger="primary" href="javascript:;" class="ease-in-out text-sm leading-default py-2.7 active after:ease-in-out after:font-awesome-5-free my-0 mx-2 flex items-center whitespace-nowrap px-4 font-medium text-slate-500 shadow-none transition-colors after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-80 dark:after:text-white/50 dark:after:text-white" aria-controls="applicationsExamples" role="button" aria-expanded="false">
          <div class="stroke-none flex h-8 w-8 items-center justify-center rounded-lg bg-center fill-current p-2.5 text-center text-black">
            <i class="text-sm leading-normal ni ni-ui-04 text-cyan-500"></i>
          </div>

          <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Contable</span>
        </a>
        <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="applicationsExamples">
          <ul class="flex flex-wrap mb-0 ml-6 list-none transition-all duration-200 ease-in-out">
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/kanban.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> K </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Kanban </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/wizard.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> W </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Wizard </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/datatables.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> D </span>
                <span class="transition-all duration-100 pointer-events-none ease"> DataTables </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/calendar.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> C </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Calendar </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/analytics.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> A </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Analytics </span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <li class="mt-0.5 w-full">
        <a collapse_trigger="primary" href="javascript:;" class="ease-in-out text-sm leading-default py-2.7 active after:ease-in-out after:font-awesome-5-free my-0 mx-2 flex items-center whitespace-nowrap px-4 font-medium text-slate-500 shadow-none transition-colors after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-80 dark:after:text-white/50 dark:after:text-white" aria-controls="applicationsExamples" role="button" aria-expanded="false">
          <div class="stroke-none flex h-8 w-8 items-center justify-center rounded-lg bg-center fill-current p-2.5 text-center text-black">
            <i class="text-sm leading-normal ni ni-ui-04 text-cyan-500"></i>
          </div>

          <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Usuarios</span>
        </a>
        <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="applicationsExamples">
          <ul class="flex flex-wrap mb-0 ml-6 list-none transition-all duration-200 ease-in-out">
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/kanban.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> K </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Kanban </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/wizard.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> W </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Wizard </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/datatables.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> D </span>
                <span class="transition-all duration-100 pointer-events-none ease"> DataTables </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/calendar.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> C </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Calendar </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/analytics.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> A </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Analytics </span>
              </a>
            </li>
          </ul>
        </div>
      </li>


      <li class="mt-0.5 w-full">
        <a collapse_trigger="primary" href="javascript:;" class="ease-in-out text-sm leading-default py-2.7 active after:ease-in-out after:font-awesome-5-free my-0 mx-2 flex items-center whitespace-nowrap px-4 font-medium text-slate-500 shadow-none transition-colors after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-80 dark:after:text-white/50 dark:after:text-white" aria-controls="applicationsExamples" role="button" aria-expanded="false">
          <div class="stroke-none flex h-8 w-8 items-center justify-center rounded-lg bg-center fill-current p-2.5 text-center text-black">
            <i class="text-sm leading-normal ni ni-ui-04 text-cyan-500"></i>
          </div>

          <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Importaciones</span>
        </a>
        <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="applicationsExamples">
          <ul class="flex flex-wrap mb-0 ml-6 list-none transition-all duration-200 ease-in-out">
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/kanban.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> K </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Kanban </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/wizard.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> W </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Wizard </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/datatables.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> D </span>
                <span class="transition-all duration-100 pointer-events-none ease"> DataTables </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/calendar.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> C </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Calendar </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/analytics.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> A </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Analytics </span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <li class="mt-0.5 w-full">
        <a collapse_trigger="primary" href="javascript:;" class="ease-in-out text-sm leading-default py-2.7 active after:ease-in-out after:font-awesome-5-free my-0 mx-2 flex items-center whitespace-nowrap px-4 font-medium text-slate-500 shadow-none transition-colors after:ml-auto after:inline-block after:font-bold after:text-slate-800/50 after:antialiased after:transition-all after:duration-200 after:content-['\f107'] dark:text-white dark:opacity-80 dark:after:text-white/50 dark:after:text-white" aria-controls="applicationsExamples" role="button" aria-expanded="false">
          <div class="stroke-none flex h-8 w-8 items-center justify-center rounded-lg bg-center fill-current p-2.5 text-center text-black">
            <i class="text-sm leading-normal ni ni-ui-04 text-cyan-500"></i>
          </div>

          <span class="ml-1 duration-300 opacity-100 pointer-events-none ease">Inversiones</span>
        </a>
        <div class="h-auto overflow-hidden transition-all duration-200 ease-in-out max-h-0" id="applicationsExamples">
          <ul class="flex flex-wrap mb-0 ml-6 list-none transition-all duration-200 ease-in-out">
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/kanban.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> K </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Kanban </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/wizard.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> W </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Wizard </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/datatables.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> D </span>
                <span class="transition-all duration-100 pointer-events-none ease"> DataTables </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/calendar.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> C </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Calendar </span>
              </a>
            </li>
            <li class="w-full">
              <a class="ease-in-out py-2.7 ml-5.4 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium text-slate-800/50 shadow-none transition-colors  dark:text-white dark:opacity-60 " href="./pages/applications/analytics.html">
                <span class="w-0 text-center transition-all duration-200 ease-in-out opacity-0 pointer-events-none"> A </span>
                <span class="transition-all duration-100 pointer-events-none ease"> Analytics </span>
              </a>
            </li>
          </ul>
        </div>
      </li>


    </ul>
  </div>

  <div sidenav-card class="mx-4 my-4">
    <!-- load phantom colors for card after: -->
    <p class="invisible hidden text-gray-800 text-red-500 text-red-600 text-blue-500 bg-gray-500/30 bg-cyan-500/30 bg-emerald-500/30 bg-orange-500/30 bg-red-500/30 after:bg-gradient-to-tl after:from-zinc-800 after:to-zinc-700 dark:bg-gradient-to-tl dark:from-slate-750 dark:to-gray-850 after:from-blue-700 after:to-cyan-500 after:from-orange-500 after:to-yellow-500 after:from-green-600 after:to-lime-400 after:from-red-600 after:to-orange-600 after:from-slate-600 after:to-slate-300 text-emerald-500 text-cyan-500 text-slate-400"></p>
    <div class="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border" sidenav-card>
      <img class="w-3/5 mx-auto" src="../assets/argon-tailwind/img/illustrations/icon-documentation.svg" alt="sidebar illustrations" />
      <div class="flex-auto w-full p-4 pt-0 text-center">
        <div class="transition-all duration-200 ease-nav-brand">
          <h6 class="mb-0 dark:text-white text-slate-700">Need help?</h6>
          <p class="mb-0 text-xs font-semibold leading-tight dark:text-white dark:opacity-60">Please check our docs</p>
        </div>
      </div>
    </div>
    <a href="https://www.creative-tim.com/learning-lab/tailwind/html/quick-start/argon-dashboard/" target="_blank" class="inline-block w-full px-8 py-2 mb-4 text-xs font-bold leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-slate-700 bg-150 hover:shadow-xs hover:-translate-y-px">Documentation</a>
    <!-- pro btn  -->
    <!-- <a class="inline-block w-full px-8 py-2 text-xs font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-black border-0 rounded-lg shadow-md select-none bg-150 bg-x-25 hover:shadow-xs hover:-translate-y-px" href="javascript:;">Upgrade to pro</a> -->
  </div>
</aside>
