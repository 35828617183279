<div class="relative flex flex-col h-full min-w-0 break-words bg-white border-0 border-solid shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl border-slate-100 bg-clip-border dark:border-slate-700">
    <div class="p-4 pb-0 rounded-t-2xl">
        <div class="flex flex-wrap -mx-3">
        <div class="w-full max-w-full px-3 md:flex-0 shrink-0 md:w-6/12">
            <h6 class="mb-0 dark:text-white">Alerta de Stock</h6>
        </div>
        <div class="flex items-center justify-end w-full max-w-full px-3 md:flex-0 shrink-0 md:w-6/12">
            <i class="mr-2 far fa-calendar-alt"></i>
            <small>{{ currentDate | date:'dd/MM/yyyy'}}</small>
        </div>
        </div>
    </div>
    <div class="flex-auto p-4">
        <ul class="flex flex-col pl-0 mb-0 rounded-lg">
            @for (row of alertStock; track $index) {
                <li class="relative justify-between block py-2 pb-0 pl-0 pr-4 border-0 rounded-t-inherit rounded-xl text-inherit">
                    <div class="flex">
                        <div class="flex items-center">
                            <button class="active:shadow-xs active:opacity-85 ease-in leading-pro text-xs bg-150 bg-x-25 rounded-3.5xl h-6 w-6 mb-0 mr-4 flex cursor-pointer items-center justify-center border border-solid p-4 text-center align-middle font-bold shadow-none transition-all hover:bg-transparent hover:opacity-75 hover:shadow-none active:text-black hover:active:bg-transparent hover:active:opacity-75 hover:active:shadow-none"
                                [ngClass]="{
                                    'border-red-600 text-red-600 hover:text-red-600 active:bg-red-600 hover:active:text-red-600': row.problem === 'infrastock', 
                                    'border-lime-500 text-lime-500 hover:text-lime-500 active:bg-lime-500 hover:active:text-lime-500' : row.problem === 'overstock'                                
                                }">
                                    <i class="fas text-3xs"
                                        [ngClass]="{
                                            'fa-arrow-down': row.problem === 'infrastock', 
                                            'fa-arrow-up': row.problem === 'overstock'
                                        }">
                                    </i>
                            </button>
                            <div class="flex flex-col">
                            <h6 class="mb-1 text-sm leading-normal text-slate-700 dark:text-white">{{row.ref}}</h6>
                            </div>
                        </div>
                        <div class="flex items-center justify-center ml-auto">
                            <p class="relative z-10 inline-block mb-0 text-sm font-semibold leading-normal bg-gradient-to-tl"
                                [ngClass]="{
                                    'text-red-600': row.problem === 'infrastock',
                                    'text-emerald-500': row.problem === 'overstock'
                                }">
                                    {{ row.qty }}
                            </p>
                        </div>
                        <div class="flex items-center justify-center ml-auto">
                            <p class="relative z-10 inline-block mb-0 text-sm font-semibold leading-normal bg-gradient-to-tl"
                                [ngClass]="{
                                    'text-red-600': row.problem === 'infrastock',
                                    'text-emerald-500': row.problem === 'overstock'
                                }">
                                    {{ row.opt }}
                            </p>
                        </div>
                    </div>
                    <hr class="h-px mt-4 mb-2 bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />
                </li>
            }
        </ul>
    </div>
</div>