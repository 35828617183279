<div>
    <div class="relative flex flex-col min-w-0 break-words shadow-xl bg-gradient-to-tl from-zinc-800 to-zinc-700 dark:bg-gradient-to-tl dark:from-slate-750 dark:to-gray-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
      <div class="flex-auto p-6">
        <div class="mb-2">
          <sup class="text-white">$</sup>
          <span class="text-4xl font-bold text-white leading-button">3,3000</span>
          <div class="mt-2 text-sm leading-normal text-white/80">Your current balance</div>
          <div>
            <span class="text-emerald-500">+ 15%</span>
            <span class="text-white/80">($250)</span>
          </div>
        </div>
        <button class="inline-block w-full px-8 py-2 text-xs font-bold leading-normal tracking-tight text-center text-black align-middle transition-all ease-in bg-white border-0 rounded-lg shadow-md cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs bg-150 bg-x-25">Add credit</button>
      </div>
      <div class="border-black/12.5 rounded-b-2xl border-t-0 border-solid p-6 pt-0">
        <div class="flex flex-wrap -mx-3">
          <div class="w-full max-w-full px-3 flex-1-0">
            <small class="text-white/80">Orders: 60%</small>
            <div class="h-0.75 my-2 text-xs leading-inherit flex overflow-visible rounded-lg bg-gray-200">
              <div class="w-3/5 transition-width duration-600 ease rounded-1.5 flex h-auto flex-col justify-center overflow-hidden whitespace-nowrap bg-emerald-500 text-center text-white"></div>
            </div>

          </div>
          <div class="w-full max-w-full px-3 flex-1-0">
            <small class="text-white/80">Sales: 40%</small>
            <div class="h-0.75 my-2 text-xs leading-inherit flex overflow-visible rounded-lg bg-gray-200">
              <div class="w-2/5 transition-width duration-600 ease rounded-1.5 flex h-auto flex-col justify-center overflow-hidden whitespace-nowrap bg-orange-500 text-center text-white"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
</div>