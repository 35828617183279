import { Component, Input } from '@angular/core';

@Component({
  selector: 'card-data-inventory',
  standalone: true,
  imports: [],
  templateUrl: './card-data-inventory.component.html',
  styleUrl: './card-data-inventory.component.scss'
})
export class CardDataInventoryComponent {
  @Input() metric: string = '';
  @Input() value!: string;
  @Input() description!: string;
  @Input() icon: string = 'ni-like-2';
  @Input() background: string = 'https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/reports4.jpg';
}
