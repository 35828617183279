<div>
    <div class="flex-auto p-6">
      <div class="p-0 overflow-x-auto">
        <table class="items-center justify-center w-full mb-0 align-top border-collapse dark:border-white/40 text-slate-500">
          <thead class="align-bottom">
            <tr>
              <th class="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Project</th>
              <th class="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Budget</th>
              <th class="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Status</th>
              <th class="px-6 py-3 pl-2 font-bold text-left uppercase align-middle bg-transparent border-b shadow-none dark:border-white/40 dark:text-white text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">Completion</th>
              <th class="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-solid shadow-none dark:border-white/40 dark:text-white tracking-none whitespace-nowrap"></th>
            </tr>
          </thead>
          <tbody class="border-t">
            <tr>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex px-2">
                  <div>
                    <img src="../assets/argon-tailwind/img/small-logos/logo-spotify.svg" class="inline-flex items-center justify-center mr-2 text-sm text-white transition-all duration-200 ease-in-out rounded-full h-9 w-9" alt="spotify" />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-xs leading-normal dark:text-white">Spotify</h6>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <p class="mb-0 text-xs font-semibold leading-normal dark:text-white dark:opacity-60">$2,500</p>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <i class="bg-cyan-500 rounded-circle mr-1.5 inline-block h-1.5 w-1.5 align-middle"></i>
                <span class="text-xs font-semibold leading-tight dark:text-white dark:opacity-60">working</span>
              </td>
              <td class="p-2 text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex items-center">
                  <span class="mr-2 text-xs font-semibold leading-tight dark:text-white dark:opacity-60">60%</span>
                  <div>
                    <div class="text-xs h-0.75 w-30 m-0 flex overflow-visible rounded-lg bg-gray-200">
                      <div class="flex flex-col justify-center w-3/5 h-auto overflow-hidden text-center text-white transition-all rounded duration-600 ease bg-gradient-to-tl from-blue-700 to-cyan-500 whitespace-nowrap" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <button class="inline-block px-5 py-2.5 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg shadow-none leading-normal text-sm ease-in bg-150 tracking-tight-rem bg-x-25 text-slate-400">
                  <i class="text-xs leading-tight fa fa-ellipsis-v dark:text-white dark:opacity-60"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex px-2">
                  <div>
                    <img src="../assets/argon-tailwind/img/small-logos/logo-invision.svg" class="inline-flex items-center justify-center mr-2 text-sm text-white transition-all duration-200 ease-in-out rounded-full h-9 w-9" alt="invision" />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-xs leading-normal dark:text-white">Invision</h6>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <p class="mb-0 text-xs font-semibold leading-normal dark:text-white dark:opacity-60">$5,000</p>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <i class="bg-emerald-500 rounded-circle mr-1.5 inline-block h-1.5 w-1.5 align-middle"></i>
                <span class="text-xs font-semibold leading-tight dark:text-white dark:opacity-60">done</span>
              </td>
              <td class="p-2 text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex items-center">
                  <span class="mr-2 text-xs font-semibold leading-tight dark:text-white dark:opacity-60">100%</span>
                  <div>
                    <div class="text-xs h-0.75 w-30 m-0 flex overflow-visible rounded-lg bg-gray-200">
                      <div class="flex flex-col justify-center w-full h-auto overflow-hidden text-center text-white transition-all rounded duration-600 ease bg-gradient-to-tl from-emerald-500 to-teal-400 whitespace-nowrap" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <button class="inline-block px-5 py-2.5 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg shadow-none leading-normal text-sm ease-in bg-150 tracking-tight-rem bg-x-25 text-slate-400" aria-haspopup="true" aria-expanded="false">
                  <i class="text-xs leading-tight fa fa-ellipsis-v dark:text-white dark:opacity-60"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex px-2">
                  <div>
                    <img src="../assets/argon-tailwind/img/small-logos/logo-jira.svg" class="inline-flex items-center justify-center mr-2 text-sm text-white transition-all duration-200 ease-in-out rounded-full h-9 w-9" alt="jira" />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-xs leading-normal dark:text-white">Jira</h6>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <p class="mb-0 text-xs font-semibold leading-normal dark:text-white dark:opacity-60">$3,400</p>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <i class="bg-red-600 rounded-circle mr-1.5 inline-block h-1.5 w-1.5 align-middle"></i>
                <span class="text-xs font-semibold leading-tight dark:text-white dark:opacity-60">canceled</span>
              </td>
              <td class="p-2 text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex items-center">
                  <span class="mr-2 text-xs font-semibold leading-tight dark:text-white dark:opacity-60">30%</span>
                  <div>
                    <div class="text-xs h-0.75 w-30 m-0 flex overflow-visible rounded-lg bg-gray-200">
                      <div class="flex flex-col justify-center h-auto overflow-hidden text-center text-white transition-all rounded duration-600 ease bg-gradient-to-tl from-red-600 to-orange-600 w-3/10 whitespace-nowrap" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="30"></div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <button class="inline-block px-5 py-2.5 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg shadow-none leading-normal text-sm ease-in bg-150 tracking-tight-rem bg-x-25 text-slate-400" aria-haspopup="true" aria-expanded="false">
                  <i class="text-xs leading-tight fa fa-ellipsis-v dark:text-white dark:opacity-60"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex px-2">
                  <div>
                    <img src="../assets/argon-tailwind/img/small-logos/logo-slack.svg" class="inline-flex items-center justify-center mr-2 text-sm text-white transition-all duration-200 ease-in-out rounded-full h-9 w-9" alt="slack" />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-xs leading-normal dark:text-white">Slack</h6>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <p class="mb-0 text-xs font-semibold leading-normal dark:text-white dark:opacity-60">$1,000</p>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <i class="bg-red-600 rounded-circle mr-1.5 inline-block h-1.5 w-1.5 align-middle"></i>
                <span class="text-xs font-semibold leading-tight dark:text-white dark:opacity-60">canceled</span>
              </td>
              <td class="p-2 text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex items-center">
                  <span class="mr-2 text-xs font-semibold leading-tight dark:text-white dark:opacity-60">0%</span>
                  <div>
                    <div class="text-xs h-0.75 w-30 m-0 flex overflow-visible rounded-lg bg-gray-200">
                      <div class="flex flex-col justify-center w-0 h-auto overflow-hidden text-center text-white transition-all rounded duration-600 ease bg-gradient-to-tl from-emerald-500 to-teal-400 whitespace-nowrap" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="0"></div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <button class="inline-block px-5 py-2.5 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg shadow-none leading-normal text-sm ease-in bg-150 tracking-tight-rem bg-x-25 text-slate-400" aria-haspopup="true" aria-expanded="false">
                  <i class="text-xs leading-tight fa fa-ellipsis-v dark:text-white dark:opacity-60"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex px-2">
                  <div>
                    <img src="https://demos.creative-tim.com/soft-ui-design-system-pro/assets/img/logos/small-logos/logo-webdev.svg" class="inline-flex items-center justify-center mr-2 text-sm text-white transition-all duration-200 ease-in-out rounded-full h-9 w-9" alt="webdev" />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-xs leading-normal dark:text-white">Webdev</h6>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <p class="mb-0 text-xs font-semibold leading-normal dark:text-white dark:opacity-60">$14,000</p>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <i class="bg-cyan-500 rounded-circle mr-1.5 inline-block h-1.5 w-1.5 align-middle"></i>
                <span class="text-xs font-semibold leading-tight dark:text-white dark:opacity-60">working</span>
              </td>
              <td class="p-2 text-center align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <div class="flex items-center">
                  <span class="mr-2 text-xs font-semibold leading-tight dark:text-white dark:opacity-60">80%</span>
                  <div>
                    <div class="text-xs h-0.75 w-30 m-0 flex overflow-visible rounded-lg bg-gray-200">
                      <div class="flex flex-col justify-center w-4/5 h-auto overflow-hidden text-center text-white transition-all rounded duration-600 ease bg-gradient-to-tl from-blue-700 to-cyan-500 whitespace-nowrap" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="80"></div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b dark:border-white/40 whitespace-nowrap shadow-transparent">
                <button class="inline-block px-5 py-2.5 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg shadow-none leading-normal text-sm ease-in bg-150 tracking-tight-rem bg-x-25 text-slate-400" aria-haspopup="true" aria-expanded="false">
                  <i class="text-xs leading-tight fa fa-ellipsis-v dark:text-white dark:opacity-60"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td class="p-2 align-middle bg-transparent border-b-0 whitespace-nowrap shadow-transparent">
                <div class="flex px-2">
                  <div>
                    <img src="../assets/argon-tailwind/img/small-logos/logo-xd.svg" class="inline-flex items-center justify-center mr-2 text-sm text-white transition-all duration-200 ease-in-out rounded-full h-9 w-9" alt="xd" />
                  </div>
                  <div class="my-auto">
                    <h6 class="mb-0 text-xs leading-normal dark:text-white">Adobe XD</h6>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b-0 whitespace-nowrap shadow-transparent">
                <p class="mb-0 text-xs font-semibold leading-normal dark:text-white dark:opacity-60">$2,300</p>
              </td>
              <td class="p-2 align-middle bg-transparent border-b-0 whitespace-nowrap shadow-transparent">
                <i class="bg-emerald-500 rounded-circle mr-1.5 inline-block h-1.5 w-1.5 align-middle"></i>
                <span class="text-xs font-semibold leading-tight dark:text-white dark:opacity-60">done</span>
              </td>
              <td class="p-2 text-center align-middle bg-transparent border-b-0 whitespace-nowrap shadow-transparent">
                <div class="flex items-center">
                  <span class="mr-2 text-xs font-semibold leading-tight dark:text-white dark:opacity-60">100%</span>
                  <div>
                    <div class="text-xs h-0.75 w-30 m-0 flex overflow-visible rounded-lg bg-gray-200">
                      <div class="flex flex-col justify-center w-full h-auto overflow-hidden text-center text-white transition-all rounded duration-600 ease bg-gradient-to-tl from-green-600 to-lime-400 whitespace-nowrap" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="p-2 align-middle bg-transparent border-b-0 whitespace-nowrap shadow-transparent">
                <button class="inline-block px-5 py-2.5 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg shadow-none leading-normal text-sm ease-in bg-150 tracking-tight-rem bg-x-25 text-slate-400" aria-haspopup="true" aria-expanded="false">
                  <i class="text-xs leading-tight fa fa-ellipsis-v dark:text-white dark:opacity-60"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>