import { Component } from '@angular/core';

@Component({
  selector: 'slide-info',
  standalone: true,
  imports: [],
  templateUrl: './slide-info.component.html',
  styleUrl: './slide-info.component.scss'
})
export class SlideInfoComponent {

}
